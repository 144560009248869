<template>
  <div class="px-3">
    <v-btn text dark @click="$router.push('/')">
      <v-icon>mdi-arrow-left</v-icon>Volver al incio
    </v-btn>
    <div class="d-flex align-center ml-6">
      <h2 class="white--text">Promociones</h2>
    </div>
    <div>
      <v-row>
        <v-col md="12" lg="6">
          <v-card @click="siguenos = true">
            <v-img src="https://apitr.xyz/dominio/tr/masdividendo.png"></v-img>
            <v-card-title>TRACKANDRACES ESCALA DE DIVIDENDOS!!</v-card-title>
            <v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
          </v-card>
        </v-col> 
         <v-col md="12" lg="6">
					<v-card @click="partida = true">
						<v-img src="https://apitr.xyz/dominio/ganador.jpg"></v-img>
						<v-card-title>Gana por Cada Referido</v-card-title>
						<v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
					</v-card>
				</v-col> 
         <v-col md="12" lg="6">
        <v-card @click="emocion = true">
          <v-img src="https://apitr.xyz/dominio/tr/30masp.png"></v-img>
          <v-card-title>(30%) Adicional PRIMER DEPOSITO</v-card-title>
          <v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
        </v-card> 

				</v-col> 
      </v-row>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>

    <v-dialog v-model="siguenos" scrollable max-width="920">
      <v-card>
        <v-card-title class="headline">TRACKANDRACES ESCALA DE DIVIDENDOS!!</v-card-title>

        <v-card-text>
<p>
    <strong>¿En qué consiste la promoción?</strong>
    <br/>
    Al seleccionar esta promocion y realizar una apuesta tipo “GANADOR” en los eventos o carreras
    seleccionadas a participar en la promoción, Trackandraces sumara una escala
    de dividendos PROMOCIONADO EN SUS REDES SOCIALES y PAGINA WEB, extra sobre
    el dividendo oficial confirmado por el hipódromo.
</p>
<p>
    <strong>¿Cómo obtengo mi bono?</strong>
</p>
<p>
    Solo debes apostar jugadas tipo “GANADOR” al ejemplar de tu preferencia en
    los HIPODROMOS TIPO AyB seleccionados por Trackandraces.com, si tu ejemplar
    llega de primero, Trackandraces automáticamente sumara el monto extra en
    unidades promocionado para el evento o carrera al dividendo oficial que
    confirme el hipódromo. Si tu ticket es ganador veras reflejado el monto
    total ganado ya incluyendo el monto extra en unidad otorgado al ejemplar.
</p>
<p>
    Vigencia: ………
</p>
<p>
    Si tienes alguna duda o preguntas y requieres de más información sobre este
    bono comunícate con nosotros:
</p>
<ul type="disc">
    <li>
        Escribirnos a Soporte@trackandraces.com
    </li>
    <li>
        Mediante el chat de la página.
    </li>
    <li>
        Mediante nuestro contacto de WhatsApp +51-933-292-975
    </li>
</ul>
<p>
    <strong>¿Cómo se liberan ganancias?</strong>
</p>
<p>
    <br/>
    Solo debes apostar jugadas tipo “GANADOR” al ejemplar de tu preferencia en
    los HIPODROMOS TIPO AyB, seleccionados por Trackandraces.com, si tu
    ejemplar llega de primero, Trackandraces automáticamente sumara el monto
    extra en unidades promocionado para el evento o carrera al dividendo
    oficial que confirme el hipódromo. Si tu ticket es ganador veras reflejado
    el monto total ganado ya incluyendo el monto extra en unidad otorgado al
    ejemplar
</p>
<p>
    Escala de Dividendos:
</p>
<table border="0" cellspacing="0" cellpadding="0" width="633">
    <tbody>
        <tr>
            <td width="155" nowrap="" valign="bottom">
            </td>
            <td width="97" nowrap="" valign="bottom">
            </td>
            <td width="133" nowrap="" valign="bottom">
                <p align="center">
                    DIVIDENDO OFICIAL
                </p>
            </td>
            <td width="127" nowrap="" valign="bottom">
                <p align="center">
                    DIVIDENDO EXTRA
                </p>
            </td>
            <td width="121" nowrap="" valign="bottom">
                <p align="center">
                    TOTAL A COBRAR
                </p>
            </td>
        </tr>
        <tr>
            <td width="155" nowrap="" valign="bottom">
                <p align="center">
                    DE 2,20 A 6,99
                </p>
            </td>
            <td width="97" nowrap="" valign="bottom">
                <p align="center">
                    (+)…(0,50)
                </p>
            </td>
            <td width="133" nowrap="" valign="bottom">
                <p align="center">
                    6,80
                </p>
            </td>
            <td width="127" nowrap="" valign="bottom">
                <p align="center">
                    0,50
                </p>
            </td>
            <td width="121" nowrap="" valign="bottom">
                <p align="center">
                    7,30
                </p>
            </td>
        </tr>
        <tr>
            <td width="155" nowrap="" valign="bottom">
                <p align="center">
                    DE 7,00 A 13,99
                </p>
            </td>
            <td width="97" nowrap="" valign="bottom">
                <p align="center">
                    (+)...(0,75)
                </p>
            </td>
            <td width="133" nowrap="" valign="bottom">
                <p align="center">
                    10,20
                </p>
            </td>
            <td width="127" nowrap="" valign="bottom">
                <p align="center">
                    0,75
                </p>
            </td>
            <td width="121" nowrap="" valign="bottom">
                <p align="center">
                    10,95
                </p>
            </td>
        </tr>
        <tr>
            <td width="155" nowrap="" valign="bottom">
                <p align="center">
                    DE 13.99 EN ADELANTE
                </p>
            </td>
            <td width="97" nowrap="" valign="bottom">
                <p align="center">
                    (+)…(1,00)
                </p>
            </td>
            <td width="133" nowrap="" valign="bottom">
                <p align="center">
                    16,60
                </p>
            </td>
            <td width="127" nowrap="" valign="bottom">
                <p align="center">
                    1,00
                </p>
            </td>
            <td width="121" nowrap="" valign="bottom">
                <p align="center">
                    17,60
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p>
    <strong>TERMINOS Y CONDICIONES:</strong>
</p>
<p>
    1. Promoción válida para las apuestas en todas las carteras o monedas de Usuarios fuera del Territorio Venezolano y
     que hayan seleccionado "Escala de dividenos" como promo del dia.
<p>
    2. Promoción valida solo para hipódromos de clasificación tipo “A / B” solo
    durante las fechas establecidas.
</p>
<p>
    3. Promocion no valida para usuarios dentro del territorio venezolano.
</p>
<p>
    4. En las carreras de Hipódromos de clasificación tipo “A” y "B” se sumará un monto extra en forma de escala, al dividendo oficial
    confirmado por el hipódromo.
</p>
<p>
    5. Para todas los tipos de moneda se aceptara y participara en la
    promocion, los tickets con un monto máximo apostado de 200 unidades por
    cliente por carrera.
</p>
<p>
    6. Si algún ticket o los tickets participantes sobrepasa el monto de 200
    unidades no participa en la promoción el excedente de las 200 unidades.
</p>
<p>
    7. El bono solo tendrá validez si en la carrera seleccionada participan un
    mínimo de seis (06) ejemplares, sin tomar en cuenta los ejemplares retirados.
</p>
<p>
    8. El bono es exclusivo para usuarios de Trackandraces que estén
    registrados en nuestra plataforma, y que sean mayores de 18 años.
</p>
<p>
    <strong>9. </strong>
Se otorgará un solo pago    <strong>“TRACKANDRACES ESCALA DE DIVIDENDOS” </strong>por cada ticket
    apostado en modalidad <strong>“GANADOR”.</strong>
    <strong>
        Cuentas de usuarios duplicados no califican para la promoción.
    </strong>
    <strong></strong>
</p>
<p>
    10. El bono
    <strong>
        “TRACKANDRACES ESCALA DE DIVIDENDOS” solo es válido y se otorgara para
        jugadas hechas con la cartera de créditos redimibles.
    </strong>
</p>
<p>
    11. El bono <strong>“TRACKANDRACES SIEMPRE DA MAS” </strong>estará vigente
    durante el periodo establecido en la promoción
</p>
<p>
    12. El pago máximo se regirá bajo las reglas establecidas según la
    clasificación del hipódromo o evento.
</p>
<p>
    13. Esta promoción está limitada a una cuenta por cliente, es decir, una
    por familia, domicilio, dirección IP, dirección de correo electrónico,
    número telefónico, número de cuenta de pago (p. ej., tarjeta de crédito o
    débito / cuenta de banco.
</p>
<p>
    14. TrackandRaces actuará con determinación para evitar que se deforme la
    finalidad del bono, que es principalmente la de probar los juegos y
    disfrutar del entretenimiento.
</p>
<p>
    15. Si se solicita un retiro antes de cumplir con los requisitos de
    apuesta, el retiro será cancelado automáticamente Trackandraces se reserva
    el derecho de quitar el bono y la ganancia desde la cuenta de juego.
</p>
<p>
    16. Los bonos no son transferibles a otros usuarios.
</p>
<p>
    17. TrackandRaces se reserva el derecho de modificar/cancelar esta
    promoción en cualquier momento. Se aplican Términos y Condiciones Generales
    de TrackandRaces y Términos y condiciones generales de Bonos.
</p>
<p>
    18. TrackandRaces se reserva el derecho de modificar los Términos y
    Condiciones de esta oferta en cualquier momento y es responsabilidad del
    usuario comprobar de forma periódica los cambios y las actualizaciones que
    se realicen en dichos Términos y Condiciones. En caso de cambios que
    incluyan los términos de requisitos de apuesta, TrackandRaces informará a
    los usuarios.
</p>
<p>
    <a name="_Hlk51697952">
        19. En caso de cambios que incluyan los términos de requisitos de
        apuesta TrackandRaces informará a sus usuarios.
    </a>
</p>


        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="partida = false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   <v-dialog v-model="partida" scrollable max-width="920">
      <v-card>
        <v-card-title class="headline">Gana por Cada Referido</v-card-title>

        <v-card-text>
          
<p>
    <strong><u>Términos y Condiciones Bono “GANA POR CADA REFERIDO</u></strong>
    <u>”</u>
</p>
<p>
    ¿En qué consiste la promoción?
</p>
<p>
    REFIERE A TUS AMIGOS O CONOCIDOS Y GANA CON @TRACKANDRACES, al momento de
    registrarte trackandraces te otorgará un código para referidos (de estar
    registrado deberá solicitarlo), el mismo es único e intransferible y lo
    podrás usar con el fin de recomendar el registro en la página de nuevos
    clientes, POR CADA CLIENTE REGISTRADO podrás obtener el 20% de la utilidad
    que genere a trackandraces la suma y resta del grupo de clientes referidos
durante los primeros 60 días, luego de ese tiempo
    transcurrido el porcentaje será de 5% mensual, desde que se registren
    dichos clientes.
</p>
<p>
    <strong>¿Cómo obtengo mi bono?</strong>
</p>
<p>
    Solo debes tener tu código de referido proporcionado por trackandraces,
    invitar a conocidos y amigos a que se registren y jueguen en
    trackandraces.com y el último día de mes podrás obtener el 20% acumulado de
    la posible utilidad obtenida por trackandraces de la suma y resta del grupo
de clientes por los primeros 60 días, luego de ese tiempo
    transcurrido el porcentaje será de 5% mensual, desde que se registren
    dichos clientes y se refleja en tu cuenta de trackandraces.
</p>
<p>
    <strong>Ejemplo 1:</strong>
    <strong></strong>
</p>
<p>
    Cierre de mes:
</p>
<p>
    CLIENTE REFERIDO 1 Ganancia generada a trackandraces 300$ 20% = 60$
</p>
<p>
    CLIENTE REFERIDO 2 Ganancia generada a trackandraces 400$ 20% = 80$
</p>
<p>
    Total de ganancia a cierre de mes por clientes referidos: 140$
</p>
<p>
    <strong>Ejemplo 2:</strong>
    <strong></strong>
</p>
<p>
    Cierre de mes:
</p>
<p>
    CLIENTE REFERIDO 1 Ganancia generada a trackandraces 400$ 20% = 80$
</p>
<p>
    CLIENTE REFERIDO 2 Ganancia generada a trackandraces 300$ 20% = 60$
</p>
<p>
    CLIENTE REFERIDO 3 Pérdida generada a trackandraces -40$ 20% = -8$
</p>
<p>
    <strong>
        Total de ganancia a cierre de mes por clientes referidos: 132$
    </strong>
    <strong></strong>
</p>
<p>
    Si tienes alguna duda de cómo activar tu bono comunícate con nosotros:
</p>
<ul type="disc">
    <li>
        Escribirnos a Soporte@trackandraces.com
    </li>
    <li>
        Mediante el chat de la página.
    </li>
    <li>
        Mediante nuestro contacto de WhatsApp +56 971238055
    </li>
</ul>
<p>
    <strong>¿Cómo se liberan ganancias?</strong>
</p>
<p>
    Por cada cliente registrado en trackandraces que haya usado tu código de
    referido, obtendrás un 20% de la posible utilidad que genere trackandraces
la suma y resta del grupo de clientes durante 60 días , luego de ese tiempo
    transcurrido el porcentaje será de 5% mensual, desde que se registren
    dichos clientes. Cada último día de mes se acreditará las ganancias
    acumuladas en tu cuenta trackandraces.com
    <br/>
    <br/>
    <br/>
</p>
<p>
    <strong>TERMINOS Y CONDICIONES:</strong>
</p>
<p>
    1. <strong>PROMOCION VALIDA PARA TODAS LAS CARTERAS</strong>.
</p>
<p>
    2. Al momento de registrarse en trackandraces.com se otorgara un código de
    referido (si ya está registrado deberá solicitarlo) dicho código será de
    carácter único e intransferible. El fin de este código es reclutar nuevos
    clientes y al momento de su registro se utilice para identificar a los
    clientes referidos por cada cliente.
</p>
<p>
    3. Por cada cliente que se registre con tu código de referido, se te
    otorgará en bono “<strong>20%”</strong> a tu cuenta registrada, de la
    posible utilidad a trackandraces por el grupo de cliente registrado con el
código de referencia por los primeros 60 días continuos , luego de ese tiempo
    transcurrido el porcentaje será de 5% mensual, desde que se registren
    dichos clientes.
    <strong>
        Cuentas de usuarios duplicados no califican para la promoción.
    </strong>
</p>
<p>
    4. El periodo de validez de la promoción inicia luego de registrarse el
    cliente con el código de referido y finaliza si el cliente referido no
    realiza al menos una jugada en un periodo de 30 días. (cada cliente se
    contabiliza por separado).
</p>
<p>
    5. Cada persona que envié algún referido podrá hacer seguimiento de los
    totales de las jugadas en tiempo real en su cuenta de trackandraces de cada
    cliente referido.
</p>
<p>
    6. Cada último día de mes se acreditara las ganancias acumuladas en la
    cuenta trackandraces.com en el tipo de moneda con el cual se registró el
    que proporciona el código de referido.
</p>
<p>
    7. Cada ultimo día de mes se enviara un correo electrónico con la relación
    de pagos donde se reflejara la cantidad de clientes referidos por el código
    y la posible utilidad
</p>
<p>
    8. Esta promoción es de carácter único e irrepetible, podrá ser disfrutada
    tantas veces como se registren con el código de referido otorgado por el
    beneficiario.
</p>
<p>
    9. Cada registro nuevo solo podrá registrarse con un solo código de
    referido.
</p>
<p>
    10. La única forma de participar en la promoción es utilizando el código de
    referido al registrarse en la página trackandraces.com, no serán válidos
    otros medios para clientes referidos.
</p>
<p>
    11. Promoción valida solo para clientes registrados por primera vez y
    utilicen el código de referido suministrado por el beneficiario.
</p>
<p>
    12. Los bonos no son transferibles a otros usuarios.
</p>
<p>
    13. TrackandRaces se reserva el derecho de modificar/cancelar esta
    promoción en cualquier momento. Se aplican Términos y Condiciones Generales
    de TrackandRaces y Términos y condiciones generales de Bonos.
</p>
<p>
    14. TrackandRaces se reserva el derecho de modificar los Términos y
    Condiciones de esta promocion en cualquier momento y es responsabilidad del
    usuario comprobar de forma periódica los cambios y las actualizaciones que
    se realicen en dichos Términos y Condiciones. En caso de cambios que
    incluyan los términos de requisitos de apuesta, TrackandRaces informará a
    los usuarios.
</p>
<p>
    15. En caso de cambios que incluyan los términos de requisitos de apuesta
    TrackandRaces informará a sus usuarios.
</p>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="partida = false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    
    <v-dialog v-model="emocion" scrollable max-width="920">
      <v-card>
        <v-card-title class="headline">(30%) Adicional PRIMER DEPOSITO </v-card-title>

        <v-card-text>
<p>
    <strong>¿En qué consiste la promoción?</strong>
    <br/>
    Al registrarte como un usuario nuevo y Seleccionar esta promocion, realiza tu  <strong> PRIMER DEPOSITO EN CUALQUIERA DE TUS CARTERAS,</strong>
    TrackandRaces te otorgará un 30% extra sobre tu primer deposito,
    <strong>
        como saldo de REDIMIBLE, al realizar tres rollover con
        jugadas a ganador, dentro del periodo de tiempo establecido por dicha promoción.
    </strong>
</p>
<p>
    <strong>¿Cómo obtengo mi bono?</strong>
</p>
<p>
    Registrate en Track and Races con cualquiera de las monedas disponibles,
    realiza TU PRIMER DEPOSITO en alguna de tus carteras de créditos
    redimibles a través de las opciones disponible de la página, Notifica tu
    deposito a través del módulo de “Recarga de Saldo” asegurándote de
    seleccionar la promoción para poder participar. Una vez verificado tu
    depósito el sistema acreditará tu saldo como crédito redimible y luego se
    hará una segunda acreditación del 30% sobre el valor depositado como
    crédito redimible al completar tres rollover con jugadas a ganador. O completando con otros depósitos los tres rollovers.
</p>
<p>
    Vigencia: indefinido.
</p>
<p>
    Si tienes alguna duda de cómo activar tu bono comunícate con nosotros:
</p>
<ul type="disc">
    <li>
        Escribirnos a Soporte@trackandraces.com
    </li>
    <li>
        Mediante el chat de la página.
    </li>
    <li>
        Mediante nuestro contacto de WhatsApp +51-933-292-975
    </li>
</ul>
<p>
    <strong>¿Cómo se liberan ganancias?</strong>
    <br/>
    Para liberar su saldo de saldo redimible, se deberá cumplir con jugar el
    300% del monto inicial depositado MAS EL BONO OTORGADO en créditos
    redimibles.
</p>
<p>
    EJM:
</p>
<p>
    (valido con jugadas a ganador y exóticas )
</p>
<table border="0" cellspacing="0" cellpadding="0" width="663">
    <tbody>
        <tr>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    <strong>DEPOSITO </strong>
                </p>
            </td>
            <td width="83" nowrap="" valign="bottom">
                <p align="center">
                    <strong>PROMO 20%</strong>
                </p>
            </td>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    <strong>SALDO </strong>
                </p>
            </td>
            <td width="76" nowrap="" valign="bottom">
                <p align="center">
                    <strong># TICKETS</strong>
                </p>
            </td>
            <td width="111" nowrap="" valign="bottom">
                <p align="center">
                    <strong>MONTO JUGADO</strong>
                </p>
            </td>
            <td width="79" nowrap="" valign="bottom">
                <p align="center">
                    <strong>PREMIO</strong>
                </p>
            </td>
            <td width="91" nowrap="" valign="bottom">
                <p align="center">
                    <strong>SALDO FINAL </strong>
                </p>
            </td>
            <td width="80" nowrap="" valign="bottom">
                <p align="center">
                    <strong>STATUS</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    $100.00
                </p>
            </td>
            <td width="83" nowrap="" valign="bottom">
                <p align="center">
                    $20.00
                </p>
            </td>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    $120.00
                </p>
            </td>
            <td width="76" nowrap="" valign="bottom">
                <p align="center">
                    5
                </p>
            </td>
            <td width="111" nowrap="" valign="bottom">
                <p align="center">
                    <strong>$50.00</strong>
                </p>
            </td>
            <td width="79" nowrap="" valign="bottom">
                <p align="center">
                    $180.00
                </p>
            </td>
            <td width="91" nowrap="" valign="bottom">
                <p align="center">
                    $240.00
                </p>
            </td>
            <td width="80" nowrap="" valign="bottom">
                <p align="center">
                    NEGADO
                </p>
            </td>
        </tr>
        <tr>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    $100.00
                </p>
            </td>
            <td width="83" nowrap="" valign="bottom">
                <p align="center">
                    $20.00
                </p>
            </td>
            <td width="72" nowrap="" valign="bottom">
                <p align="center">
                    $120.00
                </p>
            </td>
            <td width="76" nowrap="" valign="bottom">
                <p align="center">
                    10
                </p>
            </td>
            <td width="111" nowrap="" valign="bottom">
                <p align="center">
                    <strong>$360.00</strong>
                </p>
            </td>
            <td width="79" nowrap="" valign="bottom">
                <p align="center">
                    $500.00
                </p>
            </td>
            <td width="91" nowrap="" valign="bottom">
                <p align="center">
                    $860.00
                </p>
            </td>
            <td width="80" nowrap="" valign="bottom">
                <p align="center">
                    APROBADO
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p>
    <strong></strong>
</p>
<p>
    <strong>TERMINOS Y CONDICIONES:</strong>
</p>
<p>
    <strong>
        MONTO MAXIMO DE BONO A OTORGAR SERA DE 150 UNIDADES EN CREDITOS
        REDIMIBLES, SOLO AL PRIMER DEPOSITO EN LAS FECHAS ESTABLECIDAS EN LA
        PROMOCION.
    </strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>1. </strong>
    <strong>PROMOCION VALIDA PARA TODAS LAS CARTERAS DE USUARIOS </strong>
</p>
<p>
    2. Para disfrutar del bono, debes ser usuario trackandraces, es decir,
    registrarte en nuestra página y Seleccionarla como tu promo del dia.
</p>
<p>
    <strong>3. </strong>
    Se otorgará un solo bono “<strong>30%”</strong> por primer deposito diario.
    <strong>
        Cuentas de usuarios duplicados no califican para la promoción.
    </strong>
    <strong></strong>
</p>
<p>
    4. No se puede combinar en la cuenta del usuario dos bonos del mismo tipo.
</p>
<p>
    5. Se deberá jugar el equivalente al 300% de su primer deposito MAS EL
    BONO OTORGADO, para poder acceder a
    las ganancias excedentes de todas las jugadas ganadoras del primer
    deposito, <strong>Solo se tomaran en cuenta para el calculo de los rollovers las jugadas posteriores al primer deposito.</strong>
</p>
<p>
    6. El bono estará vigente durante el periodo establecido en la promoción y
    a partir de que ha sido otorgado, transcurrido ese tiempo si NO se cumplen
    con las condiciones de apuestas de la promoción el monto del bono será
    cancelado.
</p>
<p>
    7. El monto máximo de bono a otorgar será de 150 unidades en créditos
    redimibles sobre el primer deposito diario.
</p>
<p>
    8. Solo será tomado en cuenta para los Rollovers jugadas a ganador.
</p>
<p>
    9. El periodo de duración de esta promocion es de 24 horas entre las 12:01 AM hasta las 11:59 pm, 
    este es el tiempo en que se debe convertir 
    los 3 rollover para convertir el 30% en dinero redimible sobre el primer depósito diario.
</p>
<p>
    10. Si el cliente no ha completado los 3 rollover y queda sin saldo, puede hacer otros depósitos para realizar
     más jugadas y completar los rollover correspondientes y hacerse acreedor del 30% sobre el primer depósito del día.
</p>
<p>
    11. Si se solicita un retiro antes de cumplir con los requisitos de
    apuesta, el retiro será cancelado automáticamente Trackandraces se reserva
    el derecho de quitar el bono y la ganancia desde la cuenta de juego.
</p>
<p>
    12. Los bonos no son transferibles a otros usuarios.
</p>
<p>
    13. TrackandRaces se reserva el derecho de modificar/cancelar esta
    promoción en cualquier momento. Se aplican Términos y Condiciones Generales
    de TrackandRaces y Términos y condiciones generales de Bonos.
</p>
<p>
    14. TrackandRaces se reserva el derecho de modificar los Términos y
    Condiciones de esta oferta en cualquier momento y es responsabilidad del
    usuario comprobar de forma periódica los cambios y las actualizaciones que
    se realicen en dichos Términos y Condiciones. En caso de cambios que
    incluyan los términos de requisitos de apuesta, TrackandRaces informará a
    los usuarios.
</p>
<p>
    <a name="_Hlk51697952">
        15. En caso de cambios que incluyan los términos de requisitos de
        apuesta TrackandRaces informará a sus usuarios.
    </a>
</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="emocion = false">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      siguenos: false,
      partida: false,
      emocion: false,
    };
  },
};
</script>

<style></style>
